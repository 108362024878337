<template>
	<div ref="padPreview" style="padding: 5% 25%">
		<VHeading level="3" v-if="title">
			{{ title }}
		</VHeading>

		<VText classes="mt-10" size="small" :italic="true">
			{{ explanation }}
		</VText>

		<div style="width: 800px">
			<component :is="activeChart" @onLoad="handleChartLoaded" />
		</div>

		<div
			class="tab-section__content__footer__card footer"
			v-if="showLegend && keys"
		>
			<VHeading level="6" weight="semibold" classes="hide show@tablet title" style="margin-left: -58px;">
				Legend
			</VHeading>

			<div class="tab-section__content__footer__countries">
				<div
					class="tab-section__content__footer__countries__item"
					v-for="key in keys"
					:key="key.slug"
				>
					<div
						class="flag"
						:style="key.color ? { backgroundColor: key.color } : {}"
					>
						<img
							:src="require(`@/assets/imgs/${key.image}`)"
							:alt="key.name"
							v-if="key.image"
						/>
					</div>
					<VText size="smaller" weight="semibold">
						{{ key.name }}
					</VText>
				</div>
			</div>
		</div>

		<div
			class="tab-section__content__footer__card footer"
			style="margin-top: 30px"
			v-if="url"
		>
			<VHeading level="6" weight="semibold" classes="hide show@tablet title" style="margin-left: -36px;">
				URL:
			</VHeading>
			<VLink :href="url">{{ url }}</VLink>
		</div>
	</div>
</template>

// TODO: here we have a lot of duplicated code from PAD index.vue - it needs to
clean up!
<script>
import { mapGetters, mapState } from 'vuex';

import {
	FETCH_COUNTRIES,
	FETCH_PAD_SDGS,
	FETCH_SECTORS,
	FETCH_TOOLTIP
} from '../store/actions.type';

import { APP_URL } from '@/common/config.js';

import pad from '@/mixins/pad';
import EventBus from '@/eventBus';
import SDGsChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/SDGsChart';
import TotalBudgetChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/TotalBudgetChart';
import BudgetBySectorChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/BudgetBySectorChart';

export default {
	name: 'PADPreview',
	mixins: [pad],
	components: {
		SDGsChart,
		TotalBudgetChart,
		BudgetBySectorChart
	},
	data() {
		return {
			explanation: null
		};
	},
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			sdgs: state => state.pad.sdgs,
			keys: state => state.pad.keys,
			sectors: state => state.app.sectors,
			countries: state => state.app.countries
		}),
		title() {
			return this.$route.query.title;
		},
		showLegend() {
			return this.$route.query.legend === 'true';
		},
		activeChart() {
			switch (this.padFilters.indicator) {
				case 'total-budget':
					return 'TotalBudgetChart';
				case 'budget-by-sector':
					return 'BudgetBySectorChart';
				case 'sdgs':
					return 'SDGsChart';
			}
		},
		url() {
			const { countries, indicator, sdg, sector, subSdg } = this.$route.query;

			const countriesParam = countries.map(key => 'countries=' + key).join('&');

			return this.$route.query.url === 'true'
				? `${APP_URL}/policy-advocacy-data-explorer?indicator=${indicator}&${countriesParam}&sdg=${sdg}&subSdg=${subSdg}&sector=${sector}`
				: null;
		}
	},
	async created() {
		localStorage.setItem('iframeLoading', true);
		await this.fetchResources();
		await this.setData();
		await this.setAdditionalData();
		await this.fetchChartData();
		localStorage.removeItem('iframeLoading');
	},
	methods: {
		async fetchResources() {
			await this.$store.dispatch(FETCH_COUNTRIES, {
				is_active: 'true',
				graph: 'pad',
				indicator: this.padFilters.indicator
			});

			await this.$store.dispatch(FETCH_PAD_SDGS);
			await this.$store.dispatch(FETCH_SECTORS);
		},
		async setAdditionalData() {
			const { countries, explanation } = this.$route.query;

			if (explanation === 'true') {
				const { data } = await this.$store.dispatch(
					FETCH_TOOLTIP,
					`pad-${this.$route.query.indicator}-info`
				);

				this.explanation = data.data.content;
			}

			if (countries) {
				const countriesList =
					countries && typeof countries == 'object' ? countries : [countries];

				this.padFilters.countries = this.countries.filter(country =>
					countriesList.includes(String(country.id))
				);
			}
		},
		handleChartLoaded() {
			EventBus.$emit('IFRAME_LOADED', this.$refs.padPreview, this.title);
		}
	}
};
</script>
